<template>
  <div>
      <b-row>
          <b-col cols="12">
              <b-card class="my-card m-0">
                  <b-table 
                  striped
                  small
                  id="myTables"
                  bordered
                  selectable
                  selected-variant="primary"
                  select-mode="single"
                  responsive="sm"
                  :items="history"
                  :fields="fields"
                  head-row-variant="secondary"
                  >
                  <template #cell(checkbox)="row">
                      <b-form-checkbox
                      v-model="row.detailsShowing"
                      plain
                      class="vs-checkbox-con"
                      @change="row.toggleDetails"
                      >
                      </b-form-checkbox>
                  </template>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                  </b-table>
              </b-card>
          </b-col>
      </b-row>
  </div>
</template>

<script>
import { resizeable } from '@/utils/TableFunctions/resizeable.js'
export default {
  data(){
      return{
          id: this.$route.query.car_id,
          history: [],
          fields: [
            {key: 'checkbox', label: '', thStyle: {width: '30px'}},
            {key: 'id', label: 'ID', sortable: true, thStyle: {width: '150px'}},
            {key: 'date_of_change', label: 'Дата изменения', sortable: true, thStyle: {width: '150px'}},
            {key: 'record', label: 'Запись', sortable: true, thStyle: {width: '150px'}},
            {key: 'user', label: 'Пользователь', sortable: true, thStyle: {width: '150px'}},
        ],
      }
  },
  mounted(){
    resizeable()
    this.refresh()
  },
  methods: {
    refresh() {
      this.$http
          .get(`cars/${this.id}/histories`) 
          .then((res) => {
            this.history = res.data.data
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
  padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
.my-card{
  overflow: auto; 
  height: 530px;
  }
}
</style>